<template>
  <v-container grid-list-xs>
    <div class="text-center">
      <v-icon size="100" color="red" class="mt-10">mdi-close-circle-outline</v-icon>
      <p class="text-h5">There was a problem with your payment.</p>
      <v-btn
       to="/payments"
       color="primary"
       depressed
       class="mt-3"
      >
        <v-icon left>mdi-arrow-left</v-icon>
        return to payments
      </v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'paymentsError'
}
</script>

<style>

</style>